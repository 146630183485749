

.App {
  text-align: center;
  background-color: #3A3765;
  margin-bottom: 3rem;
  margin-top: 0rem;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background{
  position: fixed;
  top: 0;
  z-index: 0;
}

.card{
    padding-top: 2em;
    width: 100%;
    margin: auto;
    height: 100%;
}

.card-description{
    padding-top: 1em;
}


canvas{
    z-index: -1;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html{
  background-color: #3A3765;
}

.navbar{
    height:100px;
}
.jumbotron{
    position: relative;
    top: 0;
    left: 0;
    margin-top: 3rem;
    height: 100%;
    padding: 2rem 1rem !important;
}

.row{
    margin-top: 1rem;
}

.topPic{
    position: relative;
    top: 0;
    left: 0;
    z-index: -4;
}

a {
  color: #2e2c51;
  font-weight: 700;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
